import {useSelector,useDispatch} from 'react-redux'
import { Link } from "react-router-dom"
import { userSession,logoutUser,userRole } from "../../features/userSlice"
import { basicManagement,cashierManagement,superManagement,distributionMgmt } from "../../features/permissions"

import { useState } from 'react'

const HambugerMenu = ({setShowHM,showHM}) => {

    const dispatch = useDispatch()


    const [settingsDropS,setSettingsDropS] = useState(false)
    const [reportDropS,setReportDropS] = useState(false)
    const [distDropS,setDistDropS] = useState(false)
    const [assetDropS,setAssetDropS] = useState(false)
    const [stockDropS,setStockDropS] = useState(false)
    const [productionDropS,setProductionDropS] = useState(false)
    const [accountDropS,setAccountDropS] = useState(false)


    const user = useSelector(userSession).user

    const role = useSelector(userRole)
  

    return ( 
                <div className='block lg:hidden'>


{role && role != "Clerk" && 
<Link to={"/"} className="text-gray-900 px-3 py-2  capitalize" aria-current="page"><span>Dashboard</span>  </Link>
}


{role && 
      basicManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold navbar">
              <p className="" onClick={()=>setProductionDropS(!productionDropS)}   >Productions</p>
              <div className={`${!productionDropS && 'hidden' } origin-top-right absolute text-left flex flex-col font-bold mt-2 -ml-2 z-30 w-48 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/products" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              bakery_dining
                </span> Products</Link>
                <Link to="/ingredients" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                list_alt
                </span> Ingredients</Link>
     
                  
              </div>
</div>

}

{role && 
      cashierManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setStockDropS(!stockDropS)}   >Stock</p>
              <div className={`${!stockDropS && 'hidden' } origin-top-right absolute text-left flex flex-col font-bold mt-2 -ml-2 z-30 w-48  rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/stocks" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              inventory_2
                </span> Overall Stock</Link>
                <Link to="/collections" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                schedule
                </span> Collections</Link>
     
                  
              </div>
</div>

}




{role && 
      cashierManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setAssetDropS(!assetDropS)}   >Assets</p>
              <div className={`${!assetDropS && 'hidden' } origin-top-right absolute text-left flex flex-col font-bold mt-2 z-30 -ml-2 w-48 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/salesAssets" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              supervisor_account
                </span> Clients  </Link>
                <Link to="/salesAssets" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                delivery_dining
                </span> Transporters  </Link>
                {/* <Link to="/expenses" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                production_quantity_limits
                </span> Daily Expenses</Link> */}
                
     
                  
              </div>
</div>

}



{role && role != "Clerk" &&
<Link to={"/orders"} className="text-gray-900 px-3 py-2  capitalize" aria-current="page"><span>Orders</span>  </Link>
}



{role && 
      distributionMgmt.includes(role) && 







<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setDistDropS(!distDropS)}   >Distributions</p>
              <div className={`${!distDropS && 'hidden' } origin-top-right absolute text-left z-30 flex flex-col font-bold mt-2 -ml-2 w-48 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/distribution" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              local_shipping
                </span> Deliveries  </Link>
                <Link to="/orderPayments" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                payments
                </span> Order Payments  </Link>
       
                
     
                  
              </div>
</div>



}




        {/* navigation for small screens */}
            <div className="sm:block lg:hidden">




            {role && 
      superManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setAccountDropS(!accountDropS)} >Accounts</p>
              <div className={`${!accountDropS && 'hidden' } origin-top-right absolute text-left z-30 flex flex-col font-bold mt-2 -ml-2 w-48 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/customers" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              people
                </span> Customers  </Link>

                <Link to="/expenses" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                production_quantity_limits
                </span> Daily Expenses</Link>
                
     
                  
              </div>
</div>

}








            {role && 
      cashierManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setReportDropS(!reportDropS)}   >Reports</p>
              <div className={`${!reportDropS && 'hidden' } origin-top-right absolute z-30 text-left flex  flex-col font-bold mt-2 w-48 -ml-2 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button">
    
              <Link to="/productions" className="text-gray-900 px-3 py-2 rounded-md text-xs font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              timeline
                </span> Daily Production  </Link>
                

    
            <Link to="/distributionReport" className="text-gray-900 px-3 py-2 rounded-md text-xs font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
            signal_cellular_alt
              </span> Distribution Report  </Link>

              <Link to="/stockoutReport" className="text-gray-900 px-3 py-2 rounded-md text-xs font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
            inventory
              </span> Stock Out Report  </Link>
              

           
              {superManagement.includes(role) &&  

              <>
              <Link to="/stockinReport" className="text-gray-900 px-3 py-2 rounded-md text-xs font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              inventory_2
              </span> Stock In Report  </Link>

                  
                <Link to="/mixPricing" className="text-gray-900 px-3 py-2 rounded-md text-xs font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                price_check
                </span> Mix Pricing  </Link>

                </>
     
           }

                <Link to="/customerSalesReport" className="text-gray-900 px-3 py-2 rounded-md text-xs font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                person_pin
                </span> Customer Sales  </Link>
     
                


                <Link to="/productSales" className="text-gray-900 px-3 py-2 rounded-md text-xs font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                category
                </span> Product Sales  </Link>
     

              </div>
</div>



     
          }



            {role && 
      superManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setSettingsDropS(!settingsDropS)}   >Settings</p>
              <div className={`${!settingsDropS && 'hidden' } origin-top-right z-30 absolute text-left flex flex-col font-bold mt-2 w-48 -ml-2 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/users" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              group_add
                </span> System Users  </Link>

                
     
                  
              </div>
</div>

}




</div>

        
              
        
        

        </div>
     );
}
 
export default HambugerMenu;