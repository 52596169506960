import {createImageFromInitials,getRandomColor} from "../utils/randomPics"
import {useState} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { Link } from "react-router-dom"
import { userSession,logoutUser,userRole } from "../../features/userSlice"
import { basicManagement,cashierManagement,superManagement,distributionMgmt } from "../../features/permissions"
import HambugerMenu from "./small_menu"
const Navbar = ({...props}) => {

   const user = useSelector(userSession).user

  const role = useSelector(userRole)


  const [showHM,setShowHM] = useState(false)



  console.log(showHM)
  
  // drop down props

  const [productionDrop,setProductionProps] = useState(false)
  const [stockDrop,setstockDrop] = useState(false)
  const [assetDrop,setAssetDrop] = useState(false)
  const [distDrop,setDistDrop] = useState(false)
  const [settingsDrop,setSettingsDrop] = useState(false)
  const [reportDrop,setReportDrop] = useState(false)

  const [accountingDrop,setAccountingDrop] = useState(false)

  const dispatch = useDispatch()

   const [showDrop,setShowDrop] = useState(false)

    const setShDrop = ()=>{
        showDrop ? setShowDrop(false) : setShowDrop(true)
    }

    const [pages,setPages] = useState(['/','ingredients','products','salesAssets','orders','stocks','distribution','collections','expenses'])
   

    return (

<>
  
<nav className="navigation  shadow-lg mb-3">

<style>
    {`@media print {.navigation{display: none;}}`}
  </style>

  <div className="mx-auto px-2 sm:px-10">
    <div className="relative   flex items-center justify-between h-16">
      <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
      
      
      </div>
      <div className="flex-1 flex items-center ">
        <div className="flex-shrink-0 flex items-center">
         <p className="font-bold text-lg text-gray-900"><Link to="/" >Bakery &reg; </Link>
         {role && 
          <span className="ml-2 px-4  text-xs text-blue-600 bg-blue-200 py-1 rounded capitalize">{role}</span> 
          
         }

         </p>
        </div>
        <div className="hidden sm:block sm:ml-6">
          <div className="flex space-x-4 text-sm font-bold">



         {role && 
         role != "Clerk" && 
         <Link to={"/"} className="text-gray-900 px-3 py-2  capitalize" aria-current="page"><span>Dashboard</span>  </Link>
         }



 
      {/* {!props.isKitchen && 
      <>
      {pages.map(page=> 
      <>  
      {page == props.active ? 
            <Link to="/" className="text-gray-900 px-3 py-2  border-b-2 capitalize border-blue-500 " aria-current="page">{page == '/' ? <span>Dashboard</span> : <span>{page} </span> }</Link>
            :
            <Link to={page} className="text-gray-900 px-3 py-2  capitalize" aria-current="page">{page == '/' ? <span>Dashboard</span> : <span>{page} </span> }</Link>
    }
        </>
        )}

        </>
      } */}



  {role && 
      basicManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold navbar">
              <p className="" onClick={()=>setProductionProps(!productionDrop)}   >Productions</p>
              <div className={`${!productionDrop && 'hidden' } origin-top-right absolute text-left flex flex-col font-bold mt-4 w-48 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/products" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              bakery_dining
                </span> Products</Link>
                <Link to="/ingredients" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                list_alt
                </span> Ingredients</Link>
     
                  
              </div>
</div>

}

{role && 
      cashierManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setstockDrop(!stockDrop)}   >Stock</p>
              <div className={`${!stockDrop && 'hidden' } origin-top-right absolute text-left flex flex-col font-bold mt-4 w-48 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/stocks" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              inventory_2
                </span> Overall Stock</Link>
                <Link to="/collections" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                schedule
                </span> Collections</Link>
     
                  
              </div>
</div>

}


{role && 
      cashierManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setAssetDrop(!assetDrop)}   >Assets</p>
              <div className={`${!assetDrop && 'hidden' } origin-top-right absolute text-left flex flex-col font-bold mt-4 w-48 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/salesAssets" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              supervisor_account
                </span> Clients  </Link>
                <Link to="/salesAssets" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                delivery_dining
                </span> Transporters  </Link>
          
     
                  
              </div>
</div>

}



{role && 
         role != "Clerk" && 

<Link to={"/orders"} className="text-gray-900 px-3 py-2  capitalize" aria-current="page"><span>Orders</span>  </Link>

}

{role && 
      distributionMgmt.includes(role) && 







<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setDistDrop(!distDrop)}   >Distributions</p>
              <div className={`${!distDrop && 'hidden' } origin-top-right absolute text-left flex flex-col font-bold mt-4 w-48 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/distribution" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              local_shipping
                </span> Deliveries  </Link>
                <Link to="/orderPayments" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                payments
                </span> Order Payments  </Link>
       
                
     
                  
              </div>
</div>



}

{role && 
      superManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setAccountingDrop(!accountingDrop)} >Accounts</p>
              <div className={`${!accountingDrop && 'hidden' } origin-top-right absolute text-left flex flex-col font-bold mt-4 w-48 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/customers" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              people
                </span> Customers  </Link>

                <Link to="/expenses" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                production_quantity_limits
                </span> Daily Expenses</Link>
                
     
                  
              </div>
</div>

}





{role && 
      cashierManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setReportDrop(!reportDrop)}   >Reports</p>
              <div className={`${!reportDrop && 'hidden' } origin-top-right absolute text-left flex flex-col font-bold mt-4 w-52 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
    
              <Link to="/productions" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              timeline
                </span> Daily Production  </Link>
                

    
            <Link to="/distributionReport" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
            signal_cellular_alt
              </span> Distribution Report  </Link>

              <Link to="/stockoutReport" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
            inventory
              </span> Stock Out Report  </Link>
              

           
              {superManagement.includes(role) &&  

              <>
              <Link to="/stockinReport" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              inventory_2
              </span> Stock In Report  </Link>

                  
                <Link to="/mixPricing" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                price_check
                </span> Mix Pricing  </Link>

                </>
     
           }

                <Link to="/customerSalesReport" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                person_pin
                </span> Customer Sales  </Link>


                <Link to="/productSales" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
                category
                </span> Product Sales  </Link>


     
     
     

              </div>
</div>

}







{role && 
      superManagement.includes(role) && 



<div className="relative text-sm px-3 py-2 cursor-pointer font-bold">
              <p className="" onClick={()=>setSettingsDrop(!settingsDrop)}   >Settings</p>
              <div className={`${!settingsDrop && 'hidden' } origin-top-right absolute text-left flex flex-col font-bold mt-4 w-48 rounded-md shadow-lg py-3 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" id="dailyM" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      
              <Link to="/users" className="text-gray-900 px-3 py-2 rounded-md text-sm font-bold"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              group_add
                </span> System Users  </Link>

                
     
                  
              </div>
</div>

}



          </div>
        </div>


      </div>
      <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        

       
        <div className="ml-3 relative">

          <div class="">
            <button onClick={()=>setShDrop()} type="button" className="text-dark flex text-sm rounded-full focus:outline-none" id="user-menu-button"  aria-expanded="false" aria-haspopup="true">
              <span className="sr-only">Open user menu</span>
              {
              user && 
              
              //  user.user !== null &&
              // user.user.username
                  <img className="rounded-full" src={createImageFromInitials(40,  user.user.first_name == '' ? 'Not Available' : user.user.first_name + ' ' + user.user.last_name, getRandomColor()) } alt="" /> 

                  }      </button>
          </div>




      {showDrop ? (
          <div  className="origin-top-right text-left absolute right-0 font-bold mt-2 w-56 -mr-3  rounded-md shadow-lg py-3 px-4 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" id="user-menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">

            

              <HambugerMenu />

            
            <a className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-1"> <span className="material-icons-outlined  inline-flex align-bottom mr-2">
              account_circle
            </span> My Profile</a>

            <a className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2" onClick={()=>{dispatch(logoutUser())}} > <span className="material-icons-outlined inline-flex align-bottom mr-2" >
              logout
            </span> Sign Out</a> 
              
          </div>
    ): ''}

        </div>
      </div>
    </div>
  </div>

</nav>





</>

     );
}
 
export default Navbar;