import { Route, Redirect } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import {loginStates} from "../../features/loginSlice"
import { UserDetails } from "../../api/userAPI";
import { userSession } from "../../features/userSlice";
import {useEffect, useMemo} from 'react'
import LoginLoad from "../universal/loginLoader";


const PrivateRoute = ({children,...rest}) => {


const isAuth  = useSelector(loginStates).isAuth



const user_session = useSelector(userSession)
const user = user_session.user
const isLoading = user_session.isLoading

const dispatch = useDispatch()





useMemo(()=>{
    !isAuth && (
        localStorage.getItem('b_token') ?  dispatch(UserDetails()) : console.log('tokenNotFound')
        )
},[])




    return (
        <Route
        {...rest}
        render={({ location }) =>

            isLoading ? <LoginLoad /> :

            isAuth ? (
             <> {children} </>
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location },
                    }}
                />
            )
        }
    />  
    );
}
 
export default PrivateRoute;