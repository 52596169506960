import './App.css';
import './index.css';
import { BrowserRouter as Router,Route,Switch,Link} from 'react-router-dom'
import Ingredients from './components/pages/Ingredients'
import Products from './components/pages/Products'
import Orders from './components/pages/Orders'
import Distrubution from './components/pages/distribution';
import SalesAssets from './components/pages/salesRequirements'
import Dashboard from './components/pages/dashboard';
import { useState } from 'react';
import axios from 'axios';
import Stocks from './components/pages/stock';
import Print from './components/print';
import Collection from './components/pages/Collection';
import Expenses from './components/pages/Expense';
import Login from './components/pages/Login';
import { toast,ToastContainer } from 'react-toastify';

import {notify,contextClass} from "./components/alerts/toaster"

import PrivateRoute from './components/core/PrivateRoute';
import Users from './components/pages/Users';
import ProductionReport from './components/pages/ProductionReport';
import DistributionReport from './components/pages/DistributionReport';
import StockOutReport from './components/pages/StockOutReport';
import StockInReport from './components/pages/StockInReport';
import MixPricingReport from './components/pages/MixPricingReport';
import CustomerSalesReport from './components/pages/CustomerSalesReport';
import CustomerViewOrders from './components/layouts/reports/customerViewOrders';
import OrderPayments from './components/pages/OrderPayments';
import Test from './components/pages/Test';
import Customers from './components/pages/Customers';
import CustomerAccount from './components/pages/CustomerAccounts';
import ProductSaleReport from './components/pages/productSalesReport';

function App() {

    // PRODUCTS
    const apiUrl = process.env.REACT_APP_API_URL


    const token = localStorage.getItem('b_token')

    // product api link {GET/POST}
    const pAPI = apiUrl+'products/'
    // mix api link
    const mAPI = pAPI+'mix'

    // ingreditents api url

    const iAPI = apiUrl+'stock/'
    const bAPI  = iAPI+'brand'



    // recipe url
    const rAPI = pAPI+'ingredients'

    // mix orders
    const mOAPI = pAPI+'mixOrders'

    const updateMixAPI = pAPI+'mixOrders/'

    // add mix order ingredients

    const mOI = pAPI+'ingredientsCollection'



    // production endpoint

    const prodAPI  = pAPI+'production'

    // production update 
    const prodUpdateAPI = pAPI+'production/'


    // workforce main url
    const wAPI = apiUrl+'workforce/'

    // customer/client endpoint
    const wCAPI = wAPI+'customer'

    // driver endpoint
    const wDAPI = wAPI+'driver'

    // vehicle endpoint
    const wVAPI = wAPI+'vehicle'

    const [loading,setLoading] = useState(false)
    const [sloading,setSLoading] = useState(false)


    const [products,setProducts] = useState(null) 
  
    const getProducts = async () => {
      setLoading(true)
      const res = await axios.get(pAPI)
      setLoading(false)
      setProducts(res.data)
    }

    // adding products

    const addProduct = (product) => {
      axios.post(pAPI,product).then((res)=>{
        // success 
        res.status === 201 ? getProducts() : console.log('Error....')
      }).catch((err)=>{
        console.log(err.response)
      })
    }



    

       // submiting mix
       const addMix = (productMix) => {
        axios.post(mAPI,productMix).then((res)=>{
          // success 
          // getProducts()
          res.status === 201 ? getProducts() : console.log('Error....')
        }).catch((err)=>{
          console.log(err.request)
        })
      }

  
      const [stocks,setStock] = useState(null)
  


      const updateMix =(id,obj) => {
        axios.patch(updateMixAPI+id,obj).then((res)=> {
          res.status === 200 ? fetchMixOrders() : console.log(res.status)
        }).catch((err)=> {
          console.log(err.request)
        })
      } 


    const stockAPI = iAPI+'stock'

    const fetchStock = async () => {
        const res = await axios.get(stockAPI)
        setStock(res.data)
    }


    const presentStockAPI = iAPI+'brandStock'

    const [stockAvailable,setStockAvailable] = useState(null)

    const presentStock = async () => {
      const res = await axios.get(presentStockAPI)
      setStockAvailable(res.data)
    }

      // adding stock

      const addStock = (stock)=> {
        axios.post(stockAPI,stock).then((res)=>{
          res.status === 201 ? fetchStock() : console.log('Error....')
        }).catch((err)=>{
          console.log(err.request)
        })
      }
  

    // ingredients

    // fetching ingredients

    const [ingredients,setIngredients] = useState(null)
    

    const fetchIngredients = async () => {
      setLoading(true)
      const res = await axios.get(iAPI) 

      setIngredients(res.data)
      setLoading(false)
  

    }   
    
    // adding ingredients

    const addIngredients = (ingredient) => {
      axios.post(iAPI,ingredient).then((res)=> {
        res.status === 201 ? fetchIngredients() : console.log('Ing Error')
      }).catch((err)=>{
        console.log(err.request)
      })
    }


    
 

    const addBrand = (brand) => {
      axios.post(bAPI,brand).then((res)=> {
        res.status === 201 ? fetchIngredients() : console.log('Brand Error')
      }).catch((err)=>{
        console.log(err.request)
      })
    }

    // adding recipe
    const addRecipe = (recipe) => {
      axios.post(rAPI,recipe).then((res) => {
        res.status === 201 ? getProducts() : console.log('Recipe Error')
      }).catch((err)=>{
        console.log(err.request)
      })
    }


    const [mixorders,setMixOrders] = useState(null)
    
    const fetchMixOrders = async () => {
      setLoading(true)
      const res = await axios.get(mOAPI)
      setMixOrders(res.data)
      setLoading(false)
    }
   

   
    const addOrderMix = (mixOrder) => {
      axios.post(mOAPI,mixOrder).then((res)=> {
        res.status === 201 ? fetchMixOrders() : console.log('error')
      }).catch((err)=> {
        console.log(err.request)
      })
    }


    const addMixIngredients = (mixIngredients) => {
      axios.post(mOI,mixIngredients).then((res)=> {
        res.status === 201 ? fetchMixOrders() : console.log('error')
      }).catch((err) => {
        console.log(err.request)
      })
    }

    const addProduction = (production) => {
      axios.post(prodAPI,production).then((res)=> {
        res.status === 201 ? fetchMixOrders() : console.log('error')
      }).catch((err)=> {
        console.log(err.request)
      })
    }

    const updateProduction = (id,context) => {
      axios.patch(prodUpdateAPI+id,context).then((res)=> {
        res.status === 200 ? fetchMixOrders() : console.log(res.status)
      }).catch((err)=> {
        console.log(err.request)
      })
    }

    // WORKFORCE
    
    // customers
    const [customers,getCustomers] = useState(null)


    const fetchCustomers = async () => {
      const res = await axios.get(wCAPI,    {
        headers: {
          'Authorization': `Token ${token}` 
}})
      getCustomers(res.data)
    }

    const addClient = (context) => {
      axios.post(wCAPI,context,    {
        headers: {
          'Authorization': `Token ${token}` 
}}).then((res)=>{
        res.status === 201 ? fetchCustomers() : console.log('error')
      }).catch((err)=> {
        console.log(err.request)
      })
    }


    // drivers

    const [drivers,setDrivers] = useState(null)

    const fetchDrivers = async () => {
      const res = await axios.get(wDAPI,    {
        headers: {
          'Authorization': `Token ${token}` 
}})
      setDrivers(res.data)
    }

    const addDriver = (driver) => {
      axios.post(wDAPI,driver).then((res)=>{
        res.status === 201 ? fetchDrivers() : console.log('error')
      }).catch((err)=> {
        console.log(err.request)
      })
    }

  
    // vehicle

    const [vehicles,setVehicles] = useState(null)

    const fetchVehicles = async () => {
      const res = await axios.get(wVAPI)
      setVehicles(res.data)
    }

    const addVehicle = (vehicle) => {
      axios.post(wVAPI,vehicle).then((res)=>{
        res.status === 201 ? fetchVehicles() : console.log('err')
      }).catch((err)=>{
        console.log(err.request)
      })
    }

    const dashboardReportUrl = apiUrl+'reports/'



    const [dashboardData,setDashboardData] = useState(null)

    const dashboardReports =  async () => {
    const res = await axios.get(dashboardReportUrl,{
      headers: {
        'Authorization': `Token ${token}` 
}})
    setDashboardData(res.data)
    }

    // distribution

    const dAPI = apiUrl+'distribution/'

    // deliveries

    const sdAPI = dAPI+'deliveries'


    // orders
    const orderAPI = dAPI+'orders'

    // add stock

    const addStockAPI = dAPI+'shopStock'

    //payment
    const paymentAPI = dAPI+'payments'

    const returnDistAPI = dAPI+'return'


    const returnShopAPI = dAPI+'shopreturns'

    // last distribution

    const [lastD,setLastD] = useState(null)


    const getLastDist = async () => {
      const res = await axios.get(dAPI,{
        headers: {
          'Authorization': `Token ${token}` 
}})
      setLastD(res.data)
    }

    const startDelivery = (user)=> {
      axios.post(dAPI,user).then((res)=> {
        res.status === 201 ? getLastDist() :console.log('error')
      }).catch((err)=>{
        console.log(err.request)
      })
    }

    // creatinf a delivery

    const addDelivery = (obj)=> {
      axios.post(sdAPI,obj).then((res)=> {
        res.status === 201 ? getLastDist() : console.log('error')
      }).catch((err)=>{
        console.log(err.request)
      })
    }


    // adding orders


    const addOrderSell = (obj) => {
      console.log(obj)
      axios.post(orderAPI,obj).then((res)=>{
        res.status === 201 ? getLastDist():console.log('error')
      }).catch((err)=>{
        console.log(err.request)
      })
    }

    
  // submiting to shop items

  const addShopStock = (obj) => {
    axios.post(addStockAPI,obj).then((res)=>{
      res.status === 201 ? getLastDist(): console.log('error')
    }).catch((err)=>{
      console.log(err.request)
    })
  }


  // return products

  const returnDist = (obj) => {
    axios.post(returnDistAPI,obj).then((res)=> {
      res.status === 201 ? getLastDist() : console.log('error')
    }).catch((err)=> {
      console.log(err.request)
    })
  }

  // payment

  const addPayment = (obj)=> {
    axios.post(paymentAPI,obj).then((res)=>{
      res.status === 201 ? getLastDist(): console.log('error')
    }).catch((err)=>{
      console.log(err.request)
    })
  }

  // return shop

  const returnShop = (obj)=> {
    axios.post(returnShopAPI,obj).then((res)=> {
      res.status === 201 ? getLastDist():console.log('error')
    }).catch((err)=>{
      console.log(err.request)
    })
  }


  
  const [delivery,setDelivery] = useState(null)

  const getDelivery = async (id) => {

    const res = await axios.get(dAPI+'delivery/'+id,{
      headers: {
        'Authorization': `Token ${token}` 
}})

    setDelivery(res.data)
  }

  const [completedProductions,setCompletedProduction] = useState(null)

  const getCompletedProduction = async () => {
    const res = await axios.get(pAPI+'completedproductions')
    setCompletedProduction(res.data)
  }

  const shopSalesAPI = dAPI+'shopSales'

  const addShopSales = (obj) => {
      axios.post(shopSalesAPI,obj).then((res)=> {
        res.status === 201 ? getLastDist() :console.log('error')
      }).catch((err)=>{
        console.log(err.request)
      })
  }

  const [collections,setCollections] = useState(null)

  const collectionFetch  = pAPI+'mixFetch/'

  const updateCollectionAPI = pAPI+'collectionUpdate'
  
  const fetchCollections = async (date) => {
    console.log(date)
    const res = await axios.get(collectionFetch+date)
    setCollections(res.data)
  } 

  const updateCollection=(collectionId,updated,date)=>{
    axios.put(updateCollectionAPI+'/'+collectionId,updated).then((res)=>{
      res.status== 200 ? fetchCollections(date) : console.log('Error')
    }).catch((err)=>{
      console.log(err.request)
    })
  }


  // customer dashboard orders

  const [dashboardCustomerOrder,setDashboardCustomerOrder] = useState(null)

  const customerOrder = dashboardReportUrl+'orders/'

  const orderPerCustomer= async (customer) => {
    console.log(customer)
    const res = await axios.get(customerOrder+customer)
    setDashboardCustomerOrder(res.data)
  }

  // adding Expenses

  const expenseAPI = apiUrl+'expenses/'

  const [expenses,setExpenses] = useState(null)


  const fetchExpense = async () => {
    const res = await axios.get(expenseAPI)
    setExpenses(res.data)
  }

  const addExpense = (obj) => {
    axios.post(expenseAPI,obj).then((res)=>{
      res.status == 201 ? fetchExpense() : console.log('Error')
    }).catch((err)=>{
      console.log(err.request)
    })
  }


  // update pricings

  const updatePrice=(id,obj)=>{
    axios.patch(wAPI+'customerUpdate/'+id,obj).then((res)=>{
      res.status === 200 ? fetchCustomers():console.log('Error') 
    }).catch((err)=>{
      console.log(err.request)
    })
  }


  return (
    <Router>
    <div className="App">
        <Switch>

          
        <Route exact path="/test">
            <Test />
          </Route>

          <PrivateRoute exact path="/">
            <Dashboard orderPerCustomer={orderPerCustomer} dashboardCustomerOrder={dashboardCustomerOrder}  dashboardData={dashboardData} dashboardReports={dashboardReports}  getCompleted={getCompletedProduction} completed={completedProductions} />
          </PrivateRoute>

          <Route exact path="/login">
            <Login />
          </Route>

          {/* <Route exact  path="/ingredients" component={Ingredients}  /> */}
          <PrivateRoute exact path="/ingredients" >
            <Ingredients  fetchIngredients={fetchIngredients} addBrand={addBrand}  addIngredients={addIngredients} ingredients={ingredients} loading={loading} />
          </PrivateRoute>
          <PrivateRoute exact path="/products"> 
          <Products getProducts={getProducts} addRecipe={addRecipe} products={products} ingredients={ingredients} fetchIngredients={fetchIngredients} loading={loading}   addProduct={addProduct} addMix={addMix} />
           </PrivateRoute>

          <PrivateRoute exact path="/orders">
            <Orders updateMix={updateMix} loading={loading} addProduction={addProduction}  getProducts={getProducts} updateProduction={updateProduction} addMixIngredients={addMixIngredients}  products = {products} addOrderMix={addOrderMix} fetchMixOrders={fetchMixOrders} mixorders={mixorders}  ></Orders>
          </PrivateRoute>
          <PrivateRoute exact path="/distribution">
         <Distrubution addShopSales={addShopSales} getCompleted={getCompletedProduction} completedItems={completedProductions} delivery={delivery} getDelivery={getDelivery} returnShop={returnShop} returnDist={returnDist} addShopStock={addShopStock}   addOrderSell={addOrderSell} getLastDist={getLastDist} addDelivery={addDelivery} startDelivery={startDelivery}  lastD={lastD} vehicles={vehicles} addPayment={addPayment} drivers={drivers} fetchDrivers={fetchDrivers} fetchVehicles={fetchVehicles} fetchCustomers={fetchCustomers} customers={customers} ></Distrubution>
          </PrivateRoute>


        <PrivateRoute exact path="/orderPayments" >
      <OrderPayments 
      addShopSales={addShopSales} getCompleted={getCompletedProduction} completedItems={completedProductions} delivery={delivery} getDelivery={getDelivery} returnShop={returnShop} returnDist={returnDist} addShopStock={addShopStock}   addOrderSell={addOrderSell} getLastDist={getLastDist} addDelivery={addDelivery} startDelivery={startDelivery}  lastD={lastD} vehicles={vehicles} addPayment={addPayment} drivers={drivers} fetchDrivers={fetchDrivers} fetchVehicles={fetchVehicles} fetchCustomers={fetchCustomers} customers={customers} 
      
      />
        </PrivateRoute>

          <PrivateRoute exact path="/salesAssets">
            <SalesAssets updatePrice={updatePrice} addVehicle={addVehicle} vehicles={vehicles} fetchVehicles={fetchVehicles}  addDriver={addDriver} fetchDrivers={fetchDrivers} drivers={drivers} addClient={addClient} fetchCustomers={fetchCustomers} customers={customers} />
          </PrivateRoute>
          <PrivateRoute exact path="/stocks">
            <Stocks products={ingredients} stockAvailable={stockAvailable} presentStock={presentStock} fetchStock={fetchStock} stocks={stocks} addStock={addStock}  getProducts={fetchIngredients} />
          </PrivateRoute>

          <PrivateRoute exact path='/collections'>
              <Collection  updateCollection={updateCollection} collections = {collections} fetchCollections={fetchCollections} />
          </PrivateRoute>

          <PrivateRoute exact path='/expenses'>
             <Expenses addExpense={addExpense} fetchExpense={fetchExpense} expenses={expenses} />
          </PrivateRoute>

          <PrivateRoute exact path='/users'>
             <Users />
          </PrivateRoute>

          {/* Reports */}

          <PrivateRoute exact path='/productions'>
           <ProductionReport />
          </PrivateRoute>


          <PrivateRoute exact path='/distributionReport'>
           <DistributionReport />
          </PrivateRoute>


          <PrivateRoute exact path='/stockoutReport'>
           <StockOutReport />
          </PrivateRoute>


          <PrivateRoute exact path='/stockinReport'>
           <StockInReport />
          </PrivateRoute>



          <PrivateRoute exact path='/customers'>
           <Customers />
          </PrivateRoute>

    


          <PrivateRoute exact path='/customer/:customerId'>
           <CustomerAccount  />
          </PrivateRoute>

    
    


          <PrivateRoute exact path='/mixPricing'>
            <MixPricingReport/>
                     </PrivateRoute>


                     <PrivateRoute exact path='/customerSalesReport'>
            <CustomerSalesReport/>
                     </PrivateRoute>


                     <PrivateRoute exact path='/customerOrderViews'>
            <CustomerViewOrders />
                     </PrivateRoute>



                     <PrivateRoute exact path = '/productSales'>
                        <ProductSaleReport />
                     </PrivateRoute>


        </Switch>


        
        
<ToastContainer     toastClassName={({ type }) => contextClass[type || "default"] + 
" text-white  w-full lg:w-full relative flex p-1 h-16 items-center  align-center text-sm font-bold rounded-md justify-between items-center align-center overflow-hidden cursor-pointer"}  
/>
    </div>
    </Router>
  );
}

export default App;
