import { Fragment,useState,useEffect,useRef } from 'react'


const ProceedToCustomer = ({...props}) => { 

const [useCustomer,setUseCustomer] = useState(null)

const [myPrice,setPricing] = useState('retail')

const [order,setOrder] = useState(null)

const items = props.distItems

useEffect(() => {
setOrder(props.orderObj)
}, [props.orderObj])

useEffect(() => {
props.fetchCustomers()
}, [])



const handleSearch = (e) =>{
  const cards = btns.current.children

  const searchText = e.target.value.toLowerCase()

  for (const card  of cards){
      card.textContent.toLowerCase().indexOf(searchText) != -1 ? card.classList.remove('hidden') : card.classList.add('hidden') 
  }


  
}




const btns = useRef(null)





const orderObj = props.orderObj

// console.log(props.customers)


const setOrderObj = props.setOrderObj






const customerUpdate = (id) => {
setUseCustomer(id)
updatePricing(id)
//   setOrderObj(prevState => ({
//     ...prevState,
//     customer: id,
//     status:'active'
// }));


}




const getPrice = (product,customerId) => {

const productItem = items.find(x=>x.id==product).product  
return  props.customers.find(cus=>cus.id === customerId).prices.find(i=>i.product.id == items.find(item=>item.id == product).productdetails.id).price

}

const updatePricing = (customerId) => {
order.products.map(product=>
product.price = getPrice(product.product,customerId)
)
}




const handleSubmit = () => {  

order['status'] = 'active'
order['customer'] = useCustomer
// console.log(order)

props.addOrderSell(order)    
props.setShowCustomers(false)
}





return ( 
<Fragment>
<div
className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
>
<div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
{/*content*/}
<div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">



<div className="flex justify-between px-3 pt-1 pb-2">
<p className="text-xl font-bold">Select Customer/Client</p>
<button  onClick={()=>{
props.showModal(true)
props.setShowCustomers(false)
}}  className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
</div>


<div className="relative px-4 w-full pb-3    mt-1 text-left">


<div className="w-full">
                    
<div class="bg-white border p-2 flex">
                                                  <span class="w-auto flex justify-end items-center text-gray-500 p-2">
                                                      <i class="material-icons-outlined text-3xl">search</i>
                                                  </span>
                                                  <input onChange={handleSearch} class="w-full rounded p-2 text-sm focus:outline-none searchMeals" type="text" placeholder="Search Customers "/>
                                              </div>
</div>



<div className="w-full my-3 flex flex-wrap max-h-48 overflow-y-scroll" ref={btns} >


{props.customers === null ? (<p>Loading....</p>) : (
<>  







{props.customers.filter(cus=>cus.prices.length > 0).map(customer=>
customer.id == useCustomer ?  
<button className="px-5 m-2 focus:outline-none py-2 text-sm border rounded-full text-blue-600 border-blue-600 font-bold" onClick={()=>{
customerUpdate(customer.id)
}} >
{customer.name}
</button>
:
<button className="px-5 m-2 py-2 focus:outline-none text-sm border rounded-full text-gray-400 border-gray-400 font-bold" onClick={()=>{
customerUpdate(customer.id)
}} >
{customer.name}
</button> 

) }                   

</>
) }


</div>


{/* list orders and their prices */}
<div className="w-full  max-h-40 overflow-y-scroll">
{
useCustomer !== null && 
order !== null  &&
order.products.map(product=>
<div className="w-full shadow-sm flex justify-between font-bold py-3 px-2 my-1 text-sm uppercase">
<p>{items.find(prod=>prod.id==product.product).productdetails.name}</p>
<div className="text-right">
<p> = {product.quantity} X {product.price}</p>
<p> KSH {parseFloat(product.quantity)*product.price}</p>
</div>
</div>                        
)
}
</div>       




<div className="flex mx-auto w-1/2 my-4 mt-7">
  <button  className="w-full py-3 bg-blue-600 focus:outline-none text-white font-bold rounded-lg" onClick={handleSubmit}>Submit Order</button>
</div>



    


        


</div>




</div>
</div>
</div>
<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</Fragment>
);
}

export default ProceedToCustomer;