export const YellowLoader = () => {
    return ( 
        <div class="spinner yellowSpinner"></div>
     );
}

export const RedLoader = () => {
    return ( 
        <div class="spinner redSpinner"></div>
     );
}
 



export const BlueLoader = () => {
    return ( 
        <div class="spinner blueSpinner"></div>
     );
}



export const SYellowLoader = () => {
    return ( 
        <div class="small-spinner yellowSpinner"></div>
     );
}

export const SRedLoader = () => {
    return ( 
        <div class="small-spinner redSpinner"></div>
     );
}
 



export const SBlueLoader = () => {
    return ( 
        <div class="small-spinner blueSpinner"></div>
     );
}
