import BreadCrumbs from "../universal/breadcrumbs"
import Navbar from "../universal/navbar"
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars"
import { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { customer_state } from "../../features/workforce/customers_slice"
import { fetchCustomers } from "../../api/workforceAPI"
import { csr_states } from "../../features/reports/csr_slice"
import Spinner from "../universal/spinner"
import CustomerSalesReportTable from "../layouts/reports/customerSalesReportTable"
import moment from "moment"
import { getCustomerSalesReport } from "../../api/reports/csr_api"
import CustomerOrderViewModal from "../layouts/reports/modals/customerOrdersViewModal"
import { Fragment } from "react"





const CustomerSalesReport = () => {

    const [eDate,setEndDate] = useState(null)
    const [sDate,setStartDate] = useState(null)

    const [showModal,setShowModal] = useState(false)

    const [selectedO,setSelectedO] = useState(null)

    const [selectedCustomer,setSelectedCustomer] = useState(0)



    const customers = useSelector(customer_state)
    const dispatch = useDispatch()


    const csr_state = useSelector(csr_states)
    console.log(csr_state)

    useEffect(()=>{
        console.log('customer searching ....')
        console.log(customers.customers)
        customers.customers == null &&
        dispatch(fetchCustomers())
    },[])


    const submitSearch = () => {
        dispatch(getCustomerSalesReport(moment(sDate).format('YYYY-MM-DD'),moment(eDate).format('YYYY-MM-DD'),selectedCustomer))
    }

    const printing = () => {
        window.print()
    }

    return ( 

        <Fragment>

        <div className="relative mx-auto ">
        <Navbar active=""/>
   
    <div className="w-full px-0 lg:px-6">
    <BreadCrumbs newPage="Customer Sales Report" />


    <div className="w-full text-center headings font-bold text-lg">
            <p>MODERN LOAF</p>
            <p>CUSTOMER SALES REPORT</p>
            <style>
            {`@media screen {.headings{display: none;}}`}
            </style>

            {/* <hr /> */}
           </div>

           <style>
            {`@media print {.controlArea{display: none;}}`}
            </style>



            <div className="w-full controlArea flex flex-wrap">
        <div className="w-full text-left lg:w-1/6 p-2 controls">
            <p className="text-gray-500 font-bold py-3">Start Date</p>
            <DatePickerComponent id="datepicker" format='yyyy-MM-dd'  onChange={(e)=>setStartDate(e.target.value)} />    
            </div>

            <div className="w-full text-left lg:w-1/6 p-2 controls">
            <p className="text-gray-500 font-bold py-3">End Date</p>
            <DatePickerComponent id="datepicker" format='yyyy-MM-dd'  onChange={(e)=>setEndDate(e.target.value)} />    
            </div>

            <div className="w-full text-left lg:w-1/6 p-2 controls">
            <p className="text-gray-500 font-bold py-2">Customer</p>

                <select name=""  onChange={(e)=>setSelectedCustomer(e.target.value)} className="border border-1 rounded-xl   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200"  id="">
                    <option value="0">All</option>
                    {
                        customers.customers != null &&
                        customers.customers.map(customer=>
                                <option value={customer.id} >{customer.name}</option>
                            )
                    }
                </select>
            </div>
            



            <div className="w-full lg:w-1/6 px-4 py-4">
        <button className="w-full font-bold border-2 border-blue-600 text-blue-600 mt-3  focus:outline-none rounded-lg py-3" onClick={submitSearch} >Search</button>
            </div>

             {csr_state.data != null &&
             csr_state.data.length > 0  && 
      <div className="px-4 py-4 controls">

      <button className="border-2 border-blue-600 text-blue-600 mt-3 px-4 py-2 rounded-lg" onClick={printing}>
              <span class="material-icons-outlined">
              print
              </span>
          </button>

      </div>

             }
          
                
          


        </div>

        <div className="w-full">

             {
                 csr_state.loading ?
                 <Spinner />
                :
                csr_state.data != null &&
                <CustomerSalesReportTable setSelectedO={setSelectedO} setShowModal={setShowModal} data={csr_state.data} />

             }

             

        </div>



    </div>
    </div>


             {showModal && 

            <CustomerOrderViewModal order={selectedO} setShowModal={setShowModal} />

             }

            </Fragment>

     );
}
 
export default CustomerSalesReport;