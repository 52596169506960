import axios from 'axios'
import { errorReport, loadingGraph, reportLoading, successDistribution, successGraph, successReport } from '../features/reportSlice'

const apiUrl = process.env.REACT_APP_API_URL

const token = localStorage.getItem('b_token')


 export const ProductionReportAPI = (start,end) => dispatch => {

    return new Promise (async (resolve,reject) => {
        try {
            dispatch(reportLoading())
            const res = await axios.get(apiUrl+'reports/mixorders/'+start+'/'+end)
            res.status == 200 ? dispatch(successReport(res.data) ): dispatch(errorReport())
        }catch(error){
            reject(error)
        }
    })
}





export const FetchGraphProd = (start,end) => dispatch => {

    return new Promise (async (resolve,reject) => {
        try {
            dispatch(loadingGraph())
            const res = await axios.get(apiUrl+'reports/productionGraph/'+start+'/'+end,)
            res.status == 200 ? dispatch(successGraph(res.data) ): dispatch(errorReport())
        }catch(error){
            reject(error)
        }
    })
}


export const FetchDistributionReport = (start,end) => dispatch => {
    return new Promise (async (resolve,reject) => {
        try {
         
            dispatch(reportLoading())
            const res = await axios.get(apiUrl+'reports/distributionReport/'+start+'/'+end,{
                headers: {
                  'Authorization': `Token ${token}` 
        }})
            res.status == 200 ? dispatch(successDistribution(res.data)) : dispatch(errorReport())
        }catch(error){
            dispatch(errorReport('Error, Try Again'))
        }
    })
}
