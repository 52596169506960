import BreadCrumbs from "../universal/breadcrumbs"
import Navbar from "../universal/navbar"
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars"
import { useState } from "react"
import { useSelector,useDispatch } from "react-redux"
import moment from 'moment'
import { reportState } from "../../features/reportSlice"
import DistributionTableReport from "../layouts/reports/distributionTableReport"
import { FetchDistributionReport } from "../../api/reportAPI"
import Spinner from "../universal/spinner"
import DistributionModal from "../layouts/reports/modals/distributionReportModal"
const DistributionReport = () => {

    const reportV = useSelector(reportState)
    const [eDate,setEndDate] = useState(null)
    const [sDate,setStartDate] = useState(null)
    const [order,setOrder] = useState(null)

    // const [showReport,]

    const dispatch = useDispatch()

    const isLoading = reportV.isLoading
    const distReport   = reportV.distributionReport

    const printing = () =>{
        window.print()
    }

    const [showModal,setShowModal] = useState(false)

    const handleSubmit = () => {
        eDate  && 
        sDate  &&
        dispatch(FetchDistributionReport(moment(sDate).format('YYYY-MM-DD'),moment(eDate).format('YYYY-MM-DD')))
    }

    return (  

        <div className="relative mx-auto ">
        <Navbar active="expenses"/>
        <div className="w-full px-0 lg:px-6">
        <BreadCrumbs newPage="Distribution Report" />
           

           <div className="w-full text-center headings font-bold text-lg">
           <p>MODERN LOAF</p>


            <p>DISTRIBUTION REPORT</p>
            <style>
            {`@media screen {.headings{display: none;}}`}
            </style>

            {/* <hr /> */}
           </div>
        
        <div className="w-full p-3 flex flex-wrap items-end">
            <div className="w-full text-left lg:w-1/6 p-2 controls">
            <p className="text-gray-500 font-bold py-3">Start Date</p>
            <DatePickerComponent id="datepicker" format='yyyy-MM-dd'  onChange={(e)=>setStartDate(e.target.value)} />    
            </div>

            <div className="w-full text-left lg:w-1/6 p-2 controls">
            <p className="text-gray-500 font-bold py-3">End Date</p>
            <DatePickerComponent id="datepicker" format='yyyy-MM-dd'  onChange={(e)=>setEndDate(e.target.value)} />    
            </div>

<style>
    {`@media print {.controls{display: none;}}`}
</style>

            <div className="w-full lg:w-1/6 px-4 py-2 controls">

            { !isLoading ? 
<button className="w-full font-bold border-2 border-blue-600 text-blue-600 mb-2 focus:outline-none rounded-lg py-3" onClick={handleSubmit}>Search</button>
            :
<button disabled className="w-full font-bold border-2 border-blue-600 text-blue-600 mb-2 focus:outline-none rounded-lg py-3">Fetching Report ...</button>
            
            }


            </div>

            <div className="px-4 py-4 controls">
                <button className="border-2 border-blue-600 text-blue-600 px-4 py-2 rounded-lg" onClick={printing}>
                    <span class="material-icons-outlined">
                    print
                    </span>
                </button>
            </div>


            <div className="w-full px-2 lg:px-5 py-5 text-left">

                {
                    isLoading ? 
                    <Spinner />
                    :
                    distReport &&
                    <>
                    <DistributionTableReport no_client="no" data = {distReport} setShowModal={setShowModal} setOrder={setOrder} />

                    {showModal && 
                    <DistributionModal setShowModal={setShowModal} order={order} />
                        }
                    </>

                }

                
            
            </div>

</div>
</div>
</div>
      
    );
}
 
export default DistributionReport;