import DistributionTableReport from "./distributionTableReport";
import Navbar from "../../universal/navbar";
import BreadCrumbs from "../../universal/breadcrumbs";

const CustomerViewOrders = ({data}) => {

  
    console.log(data)


    const showCustomers = true

    return ( 

        <div className="relative mx-auto ">
        <Navbar active=""/>
   
    <div className="w-full px-0 lg:px-6">
    <BreadCrumbs newPage="Customer Sales Report" />




    <div className="w-full text-center headings font-bold text-lg">
            <p>MODERN LOAF</p>
            <p>CUSTOMER ORDERS</p>
            <p className="uppercase">{data.name}</p>
            <style>
            {`@media screen {.headings{display: none;}}`}
            </style>

            {/* <hr /> */}
           </div>


           <style>
            {`@media print {.controlArea{display: none;}}`}
            </style>


        <div className="w-full">


           <DistributionTableReport data={data.orders} no_client={showCustomers}   />

           </div>


        </div>
        </div>

     );
}
 
export default CustomerViewOrders;