import BreadCrumbs from "../universal/breadcrumbs"
import Navbar from "../universal/navbar"
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ingredient_state } from "../../features/stock/ingredients_slice"
import { fetchIngredients } from "../../api/stockAPI"
import {getStockOutReport} from "../../api/reports/sor_api"
import moment from 'moment'
import Spinner from "../universal/spinner"
import StockOutReportTable from "../layouts/reports/stockOutReportTable"
import { mpr_states } from "../../features/reports/mpr_slice"
import { store_sor } from "../../features/reports/sor_slice"
import { reportState } from "../../features/reportSlice"



const StockOutReport = () => {

    const [eDate,setEndDate] = useState(null)
    const [sDate,setStartDate] = useState(null)

    const [sIngredient,setIngredient] = useState(0)

    const ingredients = useSelector(ingredient_state)
    const storesSor = useSelector(store_sor)

    const reportsV = useSelector(reportState)


    const dispatch = useDispatch()

    useEffect(() => {
        ingredients.ingredients == null &&
        dispatch(fetchIngredients())
    },[])
    

    const printing = ()=> {
        window.print()
    }

    const submitSearch = ()=>{  
        eDate &&
        sDate &&
        dispatch(getStockOutReport(moment(sDate).format('YYYY-MM-DD'),moment(eDate).format('YYYY-MM-DD'),sIngredient))
    }


    return ( 
        <div className="relative mx-auto ">
            <Navbar active=""/>
       
        <div className="w-full px-0 lg:px-6">
        <BreadCrumbs newPage="Stock Out Report" />


        <div className="w-full text-center headings font-bold text-lg">
        <p>MODERN LOAF</p>

            <p>STOCK OUT REPORT</p>
            <style>
            {`@media screen {.headings{display: none;}}`}
            </style>

            {/* <hr /> */}
           </div>



        {/* control area */}

            <style>
            {`@media print {.controlArea{display: none;}}`}
            </style>


        <div className="w-full controlArea flex flex-wrap">
        <div className="w-full text-left lg:w-1/6 p-2 controls">
            <p className="text-gray-500 font-bold py-3">Start Date</p>
            <DatePickerComponent id="datepicker" format='yyyy-MM-dd'  onChange={(e)=>setStartDate(e.target.value)} />    
            </div>

            <div className="w-full text-left lg:w-1/6 p-2 controls">
            <p className="text-gray-500 font-bold py-3">End Date</p>
            <DatePickerComponent id="datepicker" format='yyyy-MM-dd'  onChange={(e)=>setEndDate(e.target.value)} />    
            </div>

            <div className="w-full text-left lg:w-1/6 p-2 controls">
            <p className="text-gray-500 font-bold py-2">Ingredients</p>

                <select name=""  onChange={(e)=>setIngredient(e.target.value)} className="border border-1 rounded-xl   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200"  id="">
                    <option value="0">All</option>
                    {
                        ingredients.ingredients != null &&
                        ingredients.ingredients.map(ingredient=>
                                <option value={ingredient.id} >{ingredient.name}</option>
                            )
                    }
                </select>
            </div>
            



            <div className="w-full lg:w-1/6 px-4 py-4">
        <button className="w-full font-bold border-2 border-blue-600 text-blue-600 mt-3  focus:outline-none rounded-lg py-3" onClick={submitSearch} >Search</button>
            </div>

                {storesSor.data && 
                storesSor.data.length > 0 &&
                <div className="px-4 py-4 controls">

                <button className="border-2 border-blue-600 text-blue-600 mt-3 px-4 py-2 rounded-lg" onClick={printing}>
                        <span class="material-icons-outlined">
                        print
                        </span>
                    </button>
    
                </div>
                }
          


        </div>

        <div className="w-full">

                    {
                        storesSor.loading ?
                        <Spinner />
                        :
                        storesSor.data &&
                        <StockOutReportTable data={storesSor.data} />
                    }

        </div>


        </div>



        </div>
     );
}
 
export default StockOutReport;