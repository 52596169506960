import BreadCrumbs from "../universal/breadcrumbs"
import Navbar from "../universal/navbar"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { mixes_state } from "../../features/production/mixSlice"
import { fetchMixes } from "../../api/productionAPI"
import { getMixPricingReport } from "../../api/reports/mpr_api"
import Spinner from "../universal/spinner"
import MixPricingReportTable from "../layouts/reports/mixpricingreport"
import { mpr_states } from "../../features/reports/mpr_slice"


const MixPricingReport = () => {

    const [selectedMix,setSelectedMix] = useState(0)

    const  dispatch = useDispatch()

    const mixes = useSelector(mixes_state)

    const pricing_state = useSelector(mpr_states)

    useEffect(()=>{
        mixes.mixes == null &&
        dispatch(fetchMixes())
    },[])


    const submitSearch = () => {
        dispatch(getMixPricingReport(selectedMix))
    }

    const printing = () => window.print()


    return ( 

        <div className="relative mx-auto ">
        <Navbar active=""/>

        <div className="w-full px-0 lg:px-6">
        <BreadCrumbs newPage="Mix Pricing Report" />

        <div className="w-full text-center headings font-bold text-lg">
        <p>MODERN LOAF</p>

            <p>MIX PRICING REPORT</p>
              {/* <hr /> */}
           </div>



           <style>
            {`@media screen {.headings{display: none;}}`}
            </style>

            <style>
            {`@media print {.controlArea{display: none;}}`}
            </style>






           <div className="w-full controlArea flex flex-wrap">


            <div className="w-full text-left lg:w-1/6 p-2 controls">
            <p className="text-gray-500 font-bold py-2">Select Mix</p>

                <select name=""  onChange={(e)=>setSelectedMix(e.target.value)} className="border border-1 rounded-xl   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200"  id="">
                    <option value="0">All</option>
                    {
                        mixes.mixes != null &&
                        mixes.mixes.map(mix=>
                                <option value={mix.product.id} >{mix.product.name}'s Mix</option>
                            )
                    }
                </select>
            </div>
            



            <div className="w-full lg:w-1/6 px-4 py-4">
        <button className="w-full font-bold border-2 border-blue-600 text-blue-600 mt-4  focus:outline-none rounded-lg py-3" onClick={submitSearch} >Search</button>
            </div>

                {pricing_state.data && 
                pricing_state.data.length > 0 &&
                <div className="px-4 py-4 controls">

                <button className="border-2 border-blue-600 text-blue-600 mt-3 px-4 py-2 rounded-lg" onClick={printing}>
                        <span class="material-icons-outlined">
                        print
                        </span>
                    </button>
    
                </div>
                }
          


        </div>



        <div className="w-full">

                {pricing_state.loading ? 
                
                    <Spinner />

                    :

                    pricing_state.data != null && 
                    <MixPricingReportTable data={pricing_state.data} />
            }
            

        </div>


        </div>
        </div>

     );
}
 
export default MixPricingReport;