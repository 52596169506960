import ProductsBreadcrumb from '../layouts/products/breadcrumb'
import FabProduct from '../layouts/products/fabProducts'
import ProductList from '../layouts/products/productList'
import ProductSearch from '../layouts/products/productSearch'
import Navbar from '../universal/navbar'
import { useEffect } from 'react'
import BreadCrumbs from '../universal/breadcrumbs'
import { useState } from 'react'
import EditProductModal from '../layouts/products/modals/editProduct'
import axios from 'axios'

const Products = ({...props}) => {
    const p = props
    useEffect(() => {
        p.getProducts()
        p.fetchIngredients()
    },[])

    const apiUrl = process.env.REACT_APP_API_URL



    const [openModal,setOpenModal] = useState(false)

    const [selectedProduct,setSelectedProduct] = useState(null)


    const editProductFun = (obj,pk) =>{
        setOpenModal(false)
        axios.patch(apiUrl+'products/'+pk,obj).then((res)=>{
            res.status === 200 ? p.getProducts() : console.log(res.status)
        })
    }
  

    return (
   
        <div className="relative h-screen mx-auto">
        <Navbar active="products" />
        <div className="w-full px-6">
       <BreadCrumbs newPage="Products" />
        <FabProduct addProduct = {p.addProduct}   />
        <ProductSearch />
        <ProductList setOpenModal={setOpenModal} setSelectedProduct={setSelectedProduct}  addRecipe={p.addRecipe} products={p.products} ingredients = {p.ingredients}  loading = {p.loading}   addMix={p.addMix} />
        </div>

            {openModal &&
            
            selectedProduct &&


               <EditProductModal editProductFun={editProductFun}  setOpenModal={setOpenModal} selectedProduct={selectedProduct}  />
                

            }

        </div>


        



        
     );
}
 
export default Products;